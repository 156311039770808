<template>
    <span>{{numbervalue}}</span>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'number-value',
    	props: {
    		inputnumber: Number
    	},
    	computed: {

    		numbervalue: function() {
    			var numbertext = '';
    			if (this.inputnumber) {
    				var actualVal = '';
    				if (Number.isInteger(this.inputnumber)) {
    					numbertext = this.inputnumber;
    				} else {
    					numbertext = (parseFloat(this.inputnumber).toFixed(1).replace('.', ','));
    				}
    			}
    			return numbertext;
    		}
    	}
    };
</script>